<template>
  <div class="basic">
    <v-row dense v-if="!loaded">
      <v-container fluid class="ma-10">
        <v-row dense>
          <v-col cols="12">
            <v-row class="align-center justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-col cols="12" align=center>
                기본 정보를 불러오고 있습니다.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row no-gutters v-if="loaded" :class="{'pt-3': !mobile}"  align="center" justify="center" >
      <v-col cols="12">
        <v-card flat class="pa-4 ma-3">
          <v-row dense>
            <v-col md="3" cols="6" class="subtitle-2 pa-4" align="center" >
              <div class="info_title">체중</div>
              <div class="recommended">{{ weight_init }}</div>
              <div class="unit">kg</div>
            </v-col>
            <v-col md="3" cols="6" class="subtitle-2 pa-4" align="center" >
              <div class="info_title">체지방률</div>
              <div class="recommended">{{ fatrate_init }}</div>
              <div class="unit">%</div>
            </v-col>
            <v-col md="3" cols="6" class="subtitle-2  pa-4" align="center" >
              <div class="info_title">골격근량</div>
              <div class="recommended">{{ muscle }}</div>
              <div class="unit">kg</div>
            </v-col>
            <v-col md="3" cols="6" class="subtitle-2  pa-4" align="center" >
              <div class="info_title">하루 칼로리 소비량</div>
              <div class="recommended">{{ calory_calculated.format() }}</div>
              <div class="unit">Kcal</div>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-row dense>

            <v-col cols="12" class="subtitle font-weight-bold">글리코겐 벤딩</v-col>

            <v-col md="6" cols="12" v-for="(each, i) in bending" :key="i">
              <v-card outlined class="elevation-0 pa-3 pt-1 pb-1 ma-3 mt-0 mb-0">
                <v-row dense>
                  <v-col cols="6" class="subtitle-2" align="left">
                    {{ i+1 }} 일차
                  </v-col>
                  <v-col cols="6" class="subtitle-2" align="right">{{ each.calory.format() }}Kcal</v-col>
                  <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #70AD47">{{ each.carb.format() }} kcal</v-col>
                  <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #4472C7">{{ each.protein.format() }} kcal</v-col>
                  <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #ED7D31">{{ each.fat.format() }} kcal</v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-row dense>

            <v-col cols="12" class="subtitle font-weight-bold">
              글리코겐 로딩
              <v-row dense>
                <v-col cols="6">

                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model.number="carb_rate[0]"
                    label="하루용 탄수화물 비율"
                    persistent-hint
                    hint="%"
                    :rules="[rules.limited, rules.over70]"
                    @change="change_carb_rate1"/>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model.number="carb_rate[1]"
                    label="이틀용 탄수화물 비율"
                    persistent-hint
                    hint="%"
                    :rules="[rules.limited, rules.over60]"
                    @change="change_carb_rate2" />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model.number="carb_rate[2]"
                    label="삼일용 탄수화물 비율"
                    persistent-hint
                    hint="%"
                    :rules="[rules.limited, rules.over60]"
                    @change="change_carb_rate2" />
                </v-col>
              </v-row>
            </v-col>

            <v-col md="4" cols="12" v-for="(each, i) in loading" :key="i">
              <v-card outlined class="elevation-0 pa-3 pt-1 pb-1 ma-3 mt-0 mb-0">
                <v-row dense>
                  <v-col cols="6" class="subtitle-2" align="left">
                    {{ i+1 }} 일 동안 로딩
                  </v-col>
                  <v-col cols="6" class="subtitle-2" align="right">{{ each.calory.format() }}Kcal</v-col>
                  <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #70AD47">{{ each.carb.format() }} kcal</v-col>
                  <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #4472C7">{{ each.protein.format() }} kcal</v-col>
                  <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #ED7D31">{{ each.fat.format() }} kcal</v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex';

export default {
  components: {
    // UserLoading,
  },
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      data: "data",
      userloading: "userloading",
      dietstatus: "dietstatus",
      loaded: "loaded",

      tdee: "tdee",
    }),
    getLoaded: function() {
      return this.$store.getters.loaded;
    },
  },
  data: () => {
    return {
      weight_init: 0,
      fatrate_init: 0,
      muscle: 0,
      calory_calculated: 0,
      bending: [],
      loading: [],
      carb_rate: [ 70, 60, 60 ],
      rules: {
        limited: value => value < 90 || '90 미만을 입력하세요' ,
        over70: value => value >= 70 || '70 이상 값을 입력하세요.',
        over60: value => value >= 60 || '60 이상 값을 입력하세요.',
      }
    }
  },
  watch: {
    getLoaded: async function(v, oldv) {
      if ( v == true ) {
        await this.reload_info();
      }
    },
  },
  mounted: async function() {
    if ( this.loaded ) {
      await this.reload_info();
    }
  },
  methods: {
    reload_info: async function() {

      if ( firebase.auth().currentUser ) {
        // load initial data
        const id_token = await firebase.auth().currentUser.getIdToken();
        const loading_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/loading', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        const set_init = async () => {
          if ( this.data.weight == 0 ) {
            setTimeout(() => {
              set_init();
              this.reload_info();
            }, 1000);
          } else {
            this.weight_init = this.data.weight;
            this.fatrate_init = this.data.fat;
            this.muscle = this.data.muscle;
            this.calory_calculated = this.tdee;
          }
        }

        if ( loading_.data.length > 0 ) {
          // already data
          let latest_ = loading_.data[loading_.data.length-1];

          if ( latest_.date_f != "" ) {
            await set_init();
          } else {
            this.weight_init = latest_.weight;
            this.fatrate_init = latest_.fatrate;
            this.muscle = latest_.muscle;
            this.calory_calculated = latest_.calculated_calory;
            this.date = latest_.date;
          }
        } else {
          await set_init();
        }

        if ( this.dietstatus == 1 ) {
          const id_token = await firebase.auth().currentUser.getIdToken();
          const diet_ = await axios.get(
            api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/diet', {
            params: { email: this.user.data.email },
            headers: {'id_token': id_token},
          });

          if ( diet_.data.length > 0 ) {
            let latest_diet_ = diet_.data[diet_.data.length-1];

            if ( latest_diet_.date_f == "" ) {
              // this.calory_calculated = latest_diet_.calculated_calory;

              let calculated = false;

              let today_ = this.$moment().format("YYYY-MM-DD");

              let diff_ = 0;

              const id_token = await firebase.auth().currentUser.getIdToken();
              const cheat_ = await axios.get(
                api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/cheating', {
                params: { email: this.user.data.email, date: latest_diet_.date },
                headers: {'id_token': id_token},
              });

              if ( cheat_.data.length > 0  ) {

                let week_sday = latest_diet_.date;
                let week_eday = today_;

                //
                for ( let each of cheat_.data ) {

                  if ( week_sday <= each.date && week_eday > each.date ) {
                    diff_++;
                  }

                }
                //

                let latest_cheat_ = cheat_.data[cheat_.data.length-1];

                if ( latest_cheat_.date == today_) {
                  this.calory_calculated = latest_cheat_.calculated_calory;
                  calculated = true;
                }
              }

              if ( !calculated ) {

                let weeks = this.$moment().diff(this.$moment(latest_diet_.date, "YYYY-MM-DD"), 'weeks');

                this.calory_calculated = latest_diet_.calculated_calory - latest_diet_.calory_delta_init - latest_diet_.calory_delta_weekly*(weeks - diff_);
              }

            }

          }
        }

        await this.calc_bending();
        await this.calc_loading();

      } else {
        setTimeout(() => {
          this.reload_info();
        }, 500);
      }
    },
    calc_bending: async function() {

      this.bending = [];

      const carb_factor = [ 0.3, 0.15 ];
      for ( let i = 0; i < 2 ; i++ ) {
        let t_ = {
          calory: this.calory_calculated,
          carb: this.calory_calculated * carb_factor[i],
          protein: this.weight_init * this.data.protein_multiplier * 4,
        }
        t_['fat'] = t_.calory - t_.carb - t_.protein;

        t_['calory'] = Math.round(t_['calory']/10)*10;
        t_['carb'] = Math.round(t_['carb']/10)*10;
        t_['protein'] = Math.round(t_['protein']/10)*10;
        t_['fat'] = Math.round(t_['fat']/10)*10;

        this.bending.push(t_);
      }
    },
    calc_loading: async function() {

      this.loading = [];

      const cal_factor = [ 150, 75, 50 ];
      for ( let i = 0; i < 3 ; i++ ) {
        let t_ = {
          calory: this.muscle*cal_factor[i]*0.18*4+this.tdee-(this.tdee-this.calory_calculated),
        }
        t_['carb'] = t_['calory'] * this.carb_rate[i]/100;
        t_['protein'] = this.weight_init * this.data.protein_multiplier * 4,
        t_['fat'] = t_.calory - t_.carb - t_.protein;

        t_['calory'] = Math.round(t_['calory']/10)*10;
        t_['carb'] = Math.round(t_['carb']/10)*10;
        t_['protein'] = Math.round(t_['protein']/10)*10;
        t_['fat'] = Math.round(t_['fat']/10)*10;

        this.loading.push(t_);
      }
    },
    change_carb_rate1: async function(v) {
      if ( v >= 70 && v < 90 )
        await this.calc_loading();
    },
    change_carb_rate2: async function(v) {
      if ( v >= 60 && v < 90 )
        await this.calc_loading();
    }

  }

}
</script>

<style scoped>


</style>
